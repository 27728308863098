// Migrated
<template lang="pug">
div
  .booking-table
    .booking-table__row.h6.mb-0.font-weight-medium.justify-content-bottom.row.mx-0
      .booking-table__col.col-md-2.col-lg-3.pl-md-0.d-none.d-md-flex.mt-auto.py-3(
        data-i18n="date"
      ) {{ $t('date') }}
      .booking-table__col.col-6.col-md-5.col-lg-4.mt-auto.py-3(
        data-i18n="destination"
      ) {{ $t('destination') }}
      .booking-table__col.col-3.col-md-3.mt-auto.py-3(
        data-i18n="price"
      ) {{ $t('price') }}
      .booking-table__col.col-3.col-md-2.col-lg-2.justify-content-center.pr-0.mt-auto.py-3.text-right.text-sm-center
        .text-md-nowrap.text-center(
          data-i18n="seatsLeft"
        ) {{ $t('seatsLeft') }}
    nuxt-link.booking-table__row.row.mx-0(
      v-for="(trip, index) in filteredTrips",
      :key="index",
      :to="trip.triptypeurl"
    )
      .booking-table__col.pl-md-0.col-6.col-md-2.col-lg-3.text-break.pr-0
        span {{ localeDateFormat(trip.date) }}
      .booking-table__col.col-12.col-md-5.col-lg-4
        h6.font-weight-medium.text-break.booking-content__title {{ trip.tripname }}
      .booking-table__col.col-3.col-md-3
        span.booking-content__price(
          :class="{ 'text-discounted': trip.discounted }"
        ) {{ $n(trip.price) }}
      .booking-table__col.justify-content-center.pr-0.col-3.col-md-2.col-lg-2
        span.booking-content__plats.text-right.text-sm-center(
          data-i18n="tripFull"
        ) {{ seatsLeft(trip.seats_left) }}
</template>

<script>
import calendarMixin from '@/mixins/calendar.mixin'

export default defineNuxtComponent({
  setup () {
    const { localeDateFormat } = useDate()

    return {
      localeDateFormat
    }
  },

  mixins: [calendarMixin],

  props: {
    trips: {
      type: Array,
      required: true
    }
  },

  computed: {
    filteredTrips() {
      return this.trips.slice(0, 11)
    }
  },
})
</script>